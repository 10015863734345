import React from 'react';

import * as styles from './NewsEntry.module.css';
import { Button } from './Button';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { slug as slugFn } from '@mangoart/gatsby-ui/helper/Slugger';
import { navigate } from 'gatsby';
import fecha from 'fecha';

function NewsEntry({ entry, t, locale }) {
  const { teaserimage, date, title, teaser, images, file, customurl } = entry;

  const firstImage = getImage(images[0]);
  const previewImage = getImage(teaserimage);
  const newsImage = previewImage || firstImage;

  const linkToArticle = customurl ? customurl : `/news/${slugFn(title)}`;

  return (
    <div
      className={styles.content}
      onClick={(e) => {
        if (file && file.path && file.localFile) {
          window.open(file.localFile.publicURL, '_blank');
        } else {
          navigate(linkToArticle);
          e.preventDefault();
        }
      }}
    >
      <div className={styles.leftColumn}>
        <Link to={linkToArticle} title={title}>
          <GatsbyImage image={newsImage} alt={title} style={{ width: '100%', height: 180 }} />
        </Link>
      </div>
      <div className={styles.middleColumn}>
        <div className={styles.date}>{fecha.format(new Date(date), 'D.M.YYYY')}</div>
        <h3 className={styles.title}>
          {file && file.path && file.localFile && file.localFile.publicURL ? (
            <a href={file.localFile.publicURL} target="_blank">
              {title}
            </a>
          ) : (
            <Link to={linkToArticle}>{title}</Link>
          )}
        </h3>
        <p>{teaser}</p>
      </div>
      <div className={styles.rightColumn}>
        {file && file.path && file.localFile && file.localFile.publicURL ? (
          <Button
            to={file.localFile.publicURL}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Broschüre
          </Button>
        ) : (
          <Button to={linkToArticle} icon="arrow-right">
            Weiterlesen
          </Button>
        )}
      </div>
    </div>
  );
}

export default NewsEntry;
