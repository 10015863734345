import React from 'react';
import clsx from 'clsx';
import NewsEntry from './NewsEntry';

import * as styles from './NewsList.module.css';

function sortByDate(entry1, entry2) {
  return new Date(entry2.node.date).getTime() - new Date(entry1.node.date).getTime();
}

function filterExpired({ node }) {
  if (node.expirationdate <= 0) {
    return node;
  }

  return new Date(node.expirationdate).getTime() < new Date().getTime() ? null : node;
}

export const NewsList = ({ data }) => {
  return (
    <section className={clsx(styles.content, 'defaultWidth')}>
      {data.edges
        .sort(sortByDate)
        .filter(filterExpired)
        .map(({ node }) => (
          <NewsEntry key={node.id} entry={node} />
        ))}
    </section>
  );
};
